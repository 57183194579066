
const digest = '5f8341088317e9fdfceaed931f736afe860a0828f3feb4fb2b414fc92e3d679c';
const css = `._HelpDialog_143v2_1 {
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  margin: revert;
  padding: 0;
  border: 0;
  transition: opacity 0.5s;
  overscroll-behavior: none;
  background: transparent;
  overflow: visible;
  color: var(--color-black);
}

._HelpDialog_143v2_1[open] {
  display: flex;
  flex-direction: column;
}

.__HelpDialogInner_143v2_20 {
  position: relative;
  margin-inline: 15px;
  border-radius: 6px;
  box-shadow: var(--shadow-dark-lg);
  background-color: var(--color-white);
  overflow: auto;
}

._HelpDialog_143v2_1.-closing {
  opacity: 0;
}

._HelpDialog_143v2_1::backdrop {
  opacity: 0.5;
  background-color: #000;
  transition: opacity 0.5s;
}

._HelpDialog_143v2_1.-closing::backdrop {
  opacity: 0;
}

.__HelpDialogTitle_143v2_43 {
  padding: 16px 24px;
}

.__HelpDialogTitleText_143v2_47 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.55;
}

.__HelpDialogBody_143v2_54 {
  padding: 8px 24px;
}

.__HelpDialogBodyHeadingText_143v2_58 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.__HelpDialogBodyText_143v2_65 {
  margin: 1.4em 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
}

.__HelpDialogCloseButton_143v2_72 {
  appearance: none;
  position: absolute;
  top: 8px;
  right: 12px;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  color: inherit;
}

.__HelpDialogCloseButton_143v2_72:enabled {
  cursor: pointer;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"HelpDialog":"_HelpDialog_143v2_1","helpDialog":"_HelpDialog_143v2_1","_HelpDialogInner":"__HelpDialogInner_143v2_20","helpDialogInner":"__HelpDialogInner_143v2_20","_HelpDialogTitle":"__HelpDialogTitle_143v2_43","helpDialogTitle":"__HelpDialogTitle_143v2_43","_HelpDialogTitleText":"__HelpDialogTitleText_143v2_47","helpDialogTitleText":"__HelpDialogTitleText_143v2_47","_HelpDialogBody":"__HelpDialogBody_143v2_54","helpDialogBody":"__HelpDialogBody_143v2_54","_HelpDialogBodyHeadingText":"__HelpDialogBodyHeadingText_143v2_58","helpDialogBodyHeadingText":"__HelpDialogBodyHeadingText_143v2_58","_HelpDialogBodyText":"__HelpDialogBodyText_143v2_65","helpDialogBodyText":"__HelpDialogBodyText_143v2_65","_HelpDialogCloseButton":"__HelpDialogCloseButton_143v2_72","helpDialogCloseButton":"__HelpDialogCloseButton_143v2_72"};
export { css, digest };
  