
const digest = 'f0f47cc665d31085d3ddbce812b21e7df80ef523f6f0f1f8ce5455ca6509e725';
const css = `._OrderForm_1trf3_1 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.__Title_1trf3_7 {
  display: grid;
  grid-template:
    'img label' auto
    'img  name' auto
    / 48px minmax(0, 1fr);
  gap: 0 6px;
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-gray-300);
  font-size: 14px;
  font-weight: 300;
}

.__Title_1trf3_7 img {
  grid-area: img;
  display: block;
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.__TitleLabel_1trf3_29 {
  grid-area: label;
  align-self: end;
  font-weight: 600;
}

.__TitleName_1trf3_35 {
  grid-area: name;
  align-self: start;
  font-weight: 300;
}

.__Detail_1trf3_41 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-inline: 18px;
}

.__Form_1trf3_48 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.__FormItem_1trf3_54 {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.__FormLabel_1trf3_60 {
  display: flex;
  gap: 6px;
  align-items: center;
}

.__FormLabelText_1trf3_66 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.55;
}

.__Select_1trf3_73 {
  appearance: none;
  box-sizing: border-box;
  padding: 0 12px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid var(--color-gray-200);
  background-color: var(--color-white);
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99999 10.9767L14.125 6.85168L15.3033 8.03002L9.99999 13.3334L4.69666 8.03002L5.87499 6.85168L9.99999 10.9767Z' fill='%232D3748'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center right 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: inherit;
}

.__Select_1trf3_73:enabled {
  cursor: pointer;
}

.__Select_1trf3_73.-invalid {
  border-color: var(--color-red-500);
}

.__ErrorMessage_1trf3_98 {
  margin: 0;
  color: var(--color-red-500);
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.__ErrorMessageText_1trf3_110 {
  margin: 0;
  padding: 0;
}

.__FormItemNote_1trf3_115 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: ""; /* refs https://matuzo.at/blog/2023/removing-list-styles-without-affecting-semantics */
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.42;
}

.__PriceAndAddButton_1trf3_127 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-height: 1.33;
  color: inherit;
}

.__Price_1trf3_127 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 0;
}

.__PriceOff_1trf3_142 {
  width: fit-content;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: var(--color-orange-500);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: var(--color-white);
}

.__PriceTexts_1trf3_153 {
  display: flex;
  align-items: flex-end;
  gap: 6px;
  flex-wrap: wrap;
}

/* 値段1 */
.__PriceText1_1trf3_161 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  color: var(--color-black);
}

/* 値段1（~円OFF） */
.__PriceText1_1trf3_161.-off {
  color: var(--color-orange-500);
}

/* 値段2（取り消し線） */
.__PriceText2_1trf3_174 {
  font-size: 16px;
  color: var(--color-gray-700);
  font-weight: 300;
  line-height: 1.5;
  text-decoration-line: line-through;
}

.__PriceNote_1trf3_182 {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  color: var(--color-black);
}

.__CloseButton_1trf3_189 {
  flex-shrink: 0;
  align-self: center;
  appearance: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  border: 0;
  border-radius: 6px;
  height: 32px;
  padding: 0 12px;
  background-color: var(--color-gray-100);
  color: var(--color-gray-800);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.55;
}

.__CloseButton_1trf3_189:enabled {
  cursor: pointer;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"OrderForm":"_OrderForm_1trf3_1","orderForm":"_OrderForm_1trf3_1","_Title":"__Title_1trf3_7","title":"__Title_1trf3_7","_TitleLabel":"__TitleLabel_1trf3_29","titleLabel":"__TitleLabel_1trf3_29","_TitleName":"__TitleName_1trf3_35","titleName":"__TitleName_1trf3_35","_Detail":"__Detail_1trf3_41","detail":"__Detail_1trf3_41","_Form":"__Form_1trf3_48","form":"__Form_1trf3_48","_FormItem":"__FormItem_1trf3_54","formItem":"__FormItem_1trf3_54","_FormLabel":"__FormLabel_1trf3_60","formLabel":"__FormLabel_1trf3_60","_FormLabelText":"__FormLabelText_1trf3_66","formLabelText":"__FormLabelText_1trf3_66","_Select":"__Select_1trf3_73","select":"__Select_1trf3_73","_ErrorMessage":"__ErrorMessage_1trf3_98","errorMessage":"__ErrorMessage_1trf3_98","_ErrorMessageText":"__ErrorMessageText_1trf3_110","errorMessageText":"__ErrorMessageText_1trf3_110","_FormItemNote":"__FormItemNote_1trf3_115","formItemNote":"__FormItemNote_1trf3_115","_PriceAndAddButton":"__PriceAndAddButton_1trf3_127","priceAndAddButton":"__PriceAndAddButton_1trf3_127","_Price":"__Price_1trf3_127","price":"__Price_1trf3_127","_PriceOff":"__PriceOff_1trf3_142","priceOff":"__PriceOff_1trf3_142","_PriceTexts":"__PriceTexts_1trf3_153","priceTexts":"__PriceTexts_1trf3_153","_PriceText1":"__PriceText1_1trf3_161","priceText1":"__PriceText1_1trf3_161","_PriceText2":"__PriceText2_1trf3_174","priceText2":"__PriceText2_1trf3_174","_PriceNote":"__PriceNote_1trf3_182","priceNote":"__PriceNote_1trf3_182","_CloseButton":"__CloseButton_1trf3_189","closeButton":"__CloseButton_1trf3_189"};
export { css, digest };
  