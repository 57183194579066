
const digest = '9f2d74bfed11df12d7b23d053d6724eb32032cf2c36657fafd678cb9e418141b';
const css = `._ButtonPrimary_ax1pd_1 {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  height: 48px;
  border: 0;
  border-style: solid; /* APIのカスタム設定で幅やカラーを指定されることがあるが、border-styleを指定してこないため、とりあえずsolidを指定しておく */
  border-radius: 6px;
  background-color: var(--button-primary-bg-color);
  font-size: 18px;
  font-weight: 600;
  color: var(--color-white);
}

._ButtonPrimary_ax1pd_1:enabled {
  cursor: pointer;
}

._ButtonPrimary_ax1pd_1:disabled {
  opacity: 0.4;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"ButtonPrimary":"_ButtonPrimary_ax1pd_1","buttonPrimary":"_ButtonPrimary_ax1pd_1"};
export { css, digest };
  