
const digest = 'd64427bac7b3fbe5ed7d8b1e9f32f9b6986ed4521e352398fd6f2fb039914675';
const css = `._VisuallyHiddenText_1svoy_1 {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"VisuallyHiddenText":"_VisuallyHiddenText_1svoy_1","visuallyHiddenText":"_VisuallyHiddenText_1svoy_1"};
export { css, digest };
  