import { embedButtonSettingsFetcher } from '../shared/apiFetchers/embedButtonSettingsFetcher';
import { embedProductsFetcher } from '../shared/apiFetchers/embedProductsFetcher';
import { rentalStartLoader } from './componentLoaders/rentalStartLoader';

export const widgetLoader = async () => {
  const embedDomain = document.currentScript!.dataset.rentifyEmbedDomain || window.location.host;
  const target = document.querySelector('[data-rentify-button]');
  if (!target) {
    console.error('レンタルボタンの埋め込み先要素が見つかりませんでした。');
    return;
  }

  const sku = target.getAttribute('data-rentify-sku');
  if (!sku) {
    console.error('skuを取得できませんでした。');
    return;
  }
  const [buttonSettingsRes, productsRes] = await Promise.all([
    embedButtonSettingsFetcher({ embedDomain }),
    embedProductsFetcher({ embedDomain, slug: sku }),
  ]);
  if (!buttonSettingsRes.ok) {
    console.error(buttonSettingsRes.error.message);
    return;
  }
  if (!productsRes.ok) {
    console.error(productsRes.error.message);
    return;
  }
  const { settings } = buttonSettingsRes.data;
  const { product } = productsRes.data;

  rentalStartLoader({ embedDomain, target, settings, product });
};
