
const digest = '980c51b7cc0d9f15e4a7f6b40b34f95a22a8bfda2850b5ca6bebfdbbf58c63f9';
const css = `._VisuallyHiddenText_1svoy_1 {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"VisuallyHiddenText":"_VisuallyHiddenText_1svoy_1","visuallyHiddenText":"_VisuallyHiddenText_1svoy_1"};
export { css, digest };
  