
const digest = 'c9dd87b3cd86439ebc1a62ae47d21d3365291f320c004d6ac8764cbb84d77c1a';
const css = `.__HelpButton_imfwl_1 {
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  background: transparent;
  color: inherit;
  font: inherit;
}

.__HelpButton_imfwl_1:enabled {
  cursor: pointer;
}

.__HelpButton_imfwl_1 > svg {
  display: block;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"_HelpButton":"__HelpButton_imfwl_1","helpButton":"__HelpButton_imfwl_1"};
export { css, digest };
  