import { h } from '../../shared/utils/tsxToDom';
import { getRuntimeIdentifier } from '../../shared/utils/getRuntimeIdentifier';
import { domReplacer } from '../../shared/utils/tsxToDom/domReplacer';
import { provideRentalStart, useRentalStart } from './shared/uses/useRentalStart';
import { App } from './App';
import type { Settings } from '../../shared/apiFetchers/embedButtonSettingsFetcher';
import type { Product } from '../../shared/apiFetchers/embedProductsFetcher';

const runtimeIdentifier = getRuntimeIdentifier();
export const id = runtimeIdentifier.getId('rental-start');

export const initialize = ({
  embedDomain,
  settings,
  product,
}: {
  embedDomain: string;
  settings: Settings;
  product: Product;
}) => {
  const rentalStartCtx = provideRentalStart(
    useRentalStart({ embedDomain, runtimeIdentifier, settings, product })
  );
  domReplacer(() => <App rentalStartCtx={rentalStartCtx} />, document.querySelector('#' + id));
};
