import { id, initialize } from '../../components/RentalStart';
import type { Settings } from '../../shared/apiFetchers/embedButtonSettingsFetcher';
import type { Product } from '../../shared/apiFetchers/embedProductsFetcher';

export const rentalStartLoader = ({
  embedDomain,
  target,
  settings,
  product,
}: {
  embedDomain: string;
  target: Element;
  settings: Settings;
  product: Product;
}) => {
  const placeholder = document.createElement('div');
  placeholder.setAttribute('id', id);
  target.appendChild(placeholder);
  initialize({ embedDomain, settings, product });
};
